import i from './1.svg';
import ii from './2.svg';
import iii from './3.svg';
import iv from './4.svg';
import v from './5.svg';
import vi from './6.svg';
import vii from './7.svg';
import viii from './8.svg';
import ix from './9.svg';
import x from './10.svg';
import xi from './11.svg';
import xii from './12.svg';

import Card from "../Card";

export default function CardGrid() {
    return (
        <div className="flexgrid">
            <Card id="i" src={i} auctionLink="https://metaplex.helium.com/#/auction/67xgkZrdjE9QBHVzh6yNUMQRkozLRzUL7T4FSnZgGZ9W" />
            <Card id="ii" src={ii} auctionLink="https://metaplex.helium.com/#/auction/FgGWjfyujBEkpRdZ4ft1WeFij87hSg7b32GiPqC9Zcam" />
            <Card id="iii" src={iii} auctionLink="https://metaplex.helium.com/#/auction/HsMABTGMesBdpjvbRdmpubmWbTiXvgUZnevYA4MN8LK9" />
            <Card id="iv" src={iv} auctionLink="https://metaplex.helium.com/#/auction/Biqpq6uvdd3WY7z7G5PZK2635mRLyTKcF5XMW9UE5emV" />
            <Card id="v" src={v} auctionLink="https://metaplex.helium.com/#/auction/8ddzyB7i7iLjun6hw3tVZFeSyoqnQkW7opaVeuHnHoyv" />
            <Card id="vi" src={vi} auctionLink="https://metaplex.helium.com/#/auction/5sWxNhd4pDYZk9SqYRCjTadqEb2TJfjJU3effiWvUkse" />
            <Card id="vii" src={vii} auctionLink="https://metaplex.helium.com/#/auction/8A7Yuh6KuXkC8kHpCQXY6NdEJexoMhNpGKxq9FPbZREE" />
            <Card id="viii" src={viii} auctionLink="https://metaplex.helium.com/#/auction/6HQo4nAk1Zjs7pdF8pLeauVycHxo8cxoAPc88QSYzDfq" />
            <Card id="ix" src={ix} auctionLink="https://metaplex.helium.com/#/auction/CGhKrnzgyMmbcg7zB1cF2Gn9MF7Nn8kVvysPjMripvq5" />
            <Card id="x" src={x} auctionLink="https://metaplex.helium.com/#/auction/9aKZDsZDwJ6qoQfKdAqYW3WYGjbKNTfYkNxz1Z6m8XU1" />
            <Card id="xi" src={xi} auctionLink="https://metaplex.helium.com/#/auction/9YPb2YChYfqyyKKeatBNZQhqqi7xQTVpKB3EyHN1xwsi" />
            <Card id="xii" src={xii} auctionLink="https://metaplex.helium.com/#/auction/DhZG8ETsMsgv2N7Vbvmy2QF8xqQ6Z8uaDSyPBTrtaq1s" />
        </div>
    )
}
