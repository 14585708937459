import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {HomeView, RedeemView} from "./views"

export default function App() {
    return (
        <BrowserRouter basename={'/'}>
            <Switch>
                <Route exact path="/redeem" component={() => <RedeemView/>}/>
                <Route path="/" exact component={() => <HomeView/>}/>
            </Switch>
        </BrowserRouter>
    );
}
