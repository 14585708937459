import Tilt from 'react-parallax-tilt';

export default function Card({src, id, auctionLink}) {
    return (
        <div id={id}>
            <a href={auctionLink}>
                <Tilt gyroscope={true} glareEnable={true} tiltMaxAngleX={30} tiltMaxAngleY={30} perspective={1500} scale={1.1} transitionSpeed={1000} glareMaxOpacity={1} glarePosition="all" glareColor="white" glareBorderRadius="30px">
                    <img src={src} className="card" alt="card" />
                </Tilt>
            </a>
        </div>
    )
}
