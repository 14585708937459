import bg from '../bodybg.svg';
import logo from '../zodiacwheel.svg';
import zodiac from "../zodiactext.svg";
import Fade from "react-reveal/Fade";
import { Container, Row, Col } from 'react-grid-system';
import header from './redeemheader.png';

export function RedeemView() {
    return (
        <div className="redeem">

            <Container className="redeemheader">
                <Row>
                    <Col lg={8} offset={{lg:2}}>
                    <Fade down>
                    <img src={header} className="headerimg" />
                    </Fade>
                    </Col>
                </Row>
                <Row>
                    <Col lg={8} offset={{lg:2}}>

                    <header >
                        <h1>NFT</h1>
                        <h1 style={{marginBottom: 20}}>Redeem.</h1>
                        <Fade distance="20px" left>
                <h4>Thank you for participating in the Helium Zodiac auction. All proceeds will be donated to GiveIndia to support COVID relief efforts.</h4>
                </Fade>
            </header>
                    </Col>
                </Row>
            
            </Container>
            <Container className="redeemtext">
                <Row>
                    <Col lg={6} offset={{lg:2}}>
                        <p style={{opacity: 1, color: 'white', fontWeight: 400}}>Once the auction closes on June 16th, the NFT owner will have a one-time opportunity to redeem their NFT for the physical Hotspot from Helium.</p>
                        <p>Those auction partipants who did not win will also need to "redeem" their bid to recover their SOL from the Metaplex auction program. Go to the individual auction page of the NFT on Metaplex and click the "Redeem Bid" button. This is the only way to recover any SOL you bid in the auction.</p>
                        <p>NFT Winners/Owners can follow these instructions to redeem their NFT. Do NOT burn your NFT until you're asked to do so by Helium support after submitting the contact form.</p>
                    </Col>
                </Row>
                <Row>
                    <Col lg={8} offset={{lg:2}}>
                        <div className="listpod">
                            <ul>
                                <li>Redeem your bid on the specific <a href="https://metaplex.helium.com">Metaplex</a> auction page.</li>
                                <li>You should now see the NFT in your wallet.</li>
                                <li>Fill out <a href="https://heliuminc.typeform.com/to/cW3KMI0Z">this form</a> to contact the Helium team.</li>
                                <li>You will prove your ownership by sending a specified memo and burning your NFT.</li>
                                <li>Once your ownership is verified, we will confirm your mailing address.</li>
                                <li>Helium will be responsible for shipping your Hotspot to you and paying any applicable customs.</li>
                                <li>The recipient will be responsible for any relevant local taxes.</li>
                                <li>For tax purposes, The US 915 Hotspot has a value of &#36;450 and the EU 868 Hotspot has a value of &euro;450.</li>
                            </ul>
                        </div>
                 
                    </Col>
                </Row>
            </Container>
           
        </div>
    );
}
