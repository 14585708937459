import bg from '../bodybg.svg';
import logo from '../zodiacwheel.svg';
import zodiac from "../zodiactext.svg";
import powered from "./poweredby.svg";

import CardGrid from "../../components/CardGrid";

import Fade from "react-reveal/Fade";

export function HomeView() {
    return (
        <div className="App">
            <img src={bg} className="bg" alt="logo"/>
            <img src={logo} className="App-logo" alt="logo"/>
            <header className="App-header">
                <Fade duration={3000}>
                    <img src={zodiac} className="Zodiac-Text" alt="logo"/>
                </Fade>
                <h3>HOTSPOT NFTS</h3>
                <p>
                    Welcome to Helium Zodiac - a collection of 12 original pieces of NFT art redeemable for Helium Hotspots.
                    All proceeds from the auction will be donated to GiveIndia supporting COVID relief efforts.
                </p>
                <img src={powered} className="poweredby" alt="logo"/>
                <h2>Choose a Hotspot Card...</h2>
                <CardGrid/>
            </header>
        </div>
    );
}
